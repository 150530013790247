export const PLANS = {
  basic: {
    name: 'Basic',
    price: {
      monthly: '$49',
      annually: '$29',
      full: '$349'
    },
    link: {
      monthly:
        'https://members.junglescout.com/#/checkout/payment?mt=8baab986777d132b3c5d7d611a8462e9&lang=en-US',
      annually:
        'https://members.junglescout.com/#/checkout/payment?mt=d6b11936b92e4f1582261454f1f4573c&lang=en-US'
    }
  },
  suite: {
    name: 'Suite',
    price: {
      monthly: '$69',
      annually: '$49',
      full: '$589'
    },
    link: {
      monthly:
        'https://members.junglescout.com/#/registrations?mt=4403b7423c7475d7ab3960dff9204bdf&lang=en-US',
      annually:
        'https://members.junglescout.com/#/registrations?mt=ba1a9b96c93ab092a9a84da5bb484035&lang=en-US'
    }
  },
  pro: {
    name: 'Pro',
    price: {
      monthly: '$129',
      annually: '$84',
      full: '$999'
    },
    link: {
      monthly:
        'https://members.junglescout.com/#/registrations?mt=94964b248ccddaca6104c7f26feeb386&lang=en-US',
      annually:
        'https://members.junglescout.com/#/registrations?mt=984d5942eb11d62c5bda4095a3b0edb0&lang=en-US'
    }
  },
  starter: {
    name: 'Starter',
    price: {
      monthly: '$49',
      annually: '$29',
      full: '$348'
    },
    link: {
      monthly:
        'https://members.junglescout.com/#/registrations?mt=ca14144d64db67c85c0726dc4ae3e86d&lang=en-US',
      annually:
        'https://members.junglescout.com/#/registrations?mt=e64cd2d40a4c5f4e53e524fe3513563f&lang=en-US'
    }
  },
  growthAccelerator: {
    name: 'Growth Accelerator',
    price: {
      monthly: '$79',
      annually: '$49',
      full: '$588'
    },
    link: {
      monthly:
        'https://members.junglescout.com/#/registrations?mt=063381759ab508ef722d5042059950fd&lang=en-US',
      annually:
        'https://members.junglescout.com/#/registrations?mt=598b05f0bfc2ad28bfcd2034f7759f9d&lang=en-US'
    }
  },
  brandOwner: {
    name: 'Brand Owner',
    price: {
      monthly: '$399',
      annually: '$299',
      full: '$3588'
    },
    link: {
      monthly:
        'https://members.junglescout.com/#/registrations?mt=54ef45eb486dc05222294b9a00a7c532&lang=en-US',
      annually:
        'https://members.junglescout.com/#/registrations?mt=fb8db34c4eac46c61189a436d0b05ba1&lang=en-US'
    }
  }
}

export const PLAN_DETAILS = {
  'Competitive Intelligence:': {
    basic: '-',
    suite: '-',
    pro: '-',
    starter: '-',
    growthAccelerator: '-',
    brandOwner: 'access'
  },
  'AI Assist Prompts:': {
    basic: '-',
    suite: '50 / Month',
    pro: '300 / Month',
    starter: '-',
    growthAccelerator: '100 / Month',
    brandOwner: '500 / Month'
  },
  'Product Tracker Limit:': {
    basic: '20',
    suite: '150',
    pro: '1000',
    starter: '50',
    growthAccelerator: '150',
    brandOwner: '2000'
  },
  'Product Database Searches:': {
    basic: '3 / Day',
    suite: 'Unlimited',
    pro: 'Unlimited',
    starter: '100 / Month',
    growthAccelerator: 'Unlimited',
    brandOwner: 'Unlimited'
  },
  'Keyword Scout Searches:': {
    basic: '3 / Day',
    suite: 'Unlimited',
    pro: 'Unlimited',
    starter: '50 / Month',
    growthAccelerator: '250 / Month',
    brandOwner: 'Unlimited'
  },
  'Opportunity Finder Searches:': {
    basic: '3 / Day',
    suite: 'Unlimited',
    pro: 'Unlimited',
    starter: '100 / Month',
    growthAccelerator: 'Unlimited',
    brandOwner: 'Unlimited'
  },
  'Historical Product Sales Data:': {
    basic: '1 Month',
    suite: '3 Months',
    pro: '6 Months',
    starter: '1 Month',
    growthAccelerator: '3 Months',
    brandOwner: '6 Months'
  },
  'Historical Keyword Data:': {
    basic: '1 Month',
    suite: '1 Year',
    pro: '2 Years',
    starter: '1 Month',
    growthAccelerator: '1 Year',
    brandOwner: '2 Years'
  },
  'Review Automation Customization:': {
    basic: '-',
    suite: 'Exclusions',
    pro: 'Exclusions + Delay Send',
    starter: '-',
    growthAccelerator: 'Exclusions',
    brandOwner: 'Exclusions + Delay Send'
  },
  'Listing Builder:': {
    basic: '-',
    suite: 'access',
    pro: 'access',
    starter: '-',
    growthAccelerator: 'access',
    brandOwner: 'access'
  },
  'Inventory Manager:': {
    basic: '-',
    suite: 'access',
    pro: 'access',
    starter: '-',
    growthAccelerator: 'access',
    brandOwner: 'access'
  },
  'Rank Tracker:': {
    basic: '-',
    suite: 'access',
    pro: 'access',
    starter: '-',
    growthAccelerator: 'access',
    brandOwner: 'access'
  }
}

export const US_PLANS = {
  monthly: {
    starter: {
      url:
        'https://members.junglescout.com/#/registrations?mt=3a4bae09653004421add96f61105e443&lang=en-US',
      price: 49.0
    },
    growthaccelerator: {
      url:
        'https://members.junglescout.com/#/registrations?mt=c54fc6c986ea9484080c7c17893ed90f&lang=en-US',
      price: 79.0
    },
    brandowner: {
      url:
        'https://members.junglescout.com/#/registrations?mt=54ef45eb486dc05222294b9a00a7c532&lang=en-US',
      price: 399.0
    }
  },
  yearly: {
    starter: {
      url:
        'https://members.junglescout.com/#/registrations?mt=8ec38d2fff00175fdf8c7046f3e7072c&lang=en-US',
      price: 348.0
    },
    growthaccelerator: {
      url:
        'https://members.junglescout.com/#/registrations?mt=dc0ab890cb8d054b9e15b1996985bfab&lang=en-US',
      price: 588.0
    },
    brandowner: {
      url:
        'https://members.junglescout.com/#/registrations?mt=fb8db34c4eac46c61189a436d0b05ba1&lang=en-US',
      price: 3588.0
    }
  }
}

// TODO 2024: Update these URLS to be new checkout URLs. DO NOT USE.
// export const CN_STRIPE_HOSTED_PLANS = {
//   monthly: {
//     starter: {
//       url:
//         'https://login.junglescout.cn/register?plan=price_1L0Sc7KWfSycpO9EhpAOZwPM',
//       price: 69.0
//     },
//     growthaccelerator: {
//       url:
//         'https://login.junglescout.cn/register?plan=price_1L0Sc7KWfSycpO9EhpAOZwPM',
//       price: 69.0
//     },
//     brandowner: {
//       url:
//         'https://login.junglescout.cn/register?plan=price_1L0Sc7KWfSycpO9EhpAOZwPM',
//       price: 69.0
//     }
//   },
//   yearly: {
//     starter: {
//       url:
//         'https://login.junglescout.cn/register?plan=price_1LIK0dKWfSycpO9EK1NE10iK',
//       price: 197.0
//     },
//     growthaccelerator: {
//       url:
//         'https://login.junglescout.cn/register?plan=price_1L0Sc7KWfSycpO9EM1VZ00gK',
//       price: 399.0
//     },
//     brandowner: {
//       url:
//         'https://login.junglescout.cn/register?plan=price_1LLitaKWfSycpO9E3RMDL8yU&quantity=5&min_quantity=5',
//       price: 799.0
//     }
//   }
// }

export const CN_PLANS = {
  monthly: {
    starter: {
      url:
        'https://login.junglescout.cn/register?plan=price_1L0Sc7KWfSycpO9EhpAOZwPM',
      price: 69.0
    },
    growthaccelerator: {
      url:
        'https://login.junglescout.cn/register?plan=price_1L0Sc7KWfSycpO9EhpAOZwPM',
      price: 69.0
    },
    brandowner: {
      url:
        'https://members.junglescout.com/#/registrations?mt=efcc603dd9a8bd6912651276c5c8b1be',
      price: 219.0
    }
  },
  yearly: {
    starter: {
      url:
        'https://login.junglescout.cn/register?plan=price_1LIK0dKWfSycpO9EK1NE10iK',
      price: 197.0
    },
    growthaccelerator: {
      url:
        'https://login.junglescout.cn/register?plan=price_1L0Sc7KWfSycpO9EM1VZ00gK',
      price: 399.0
    },
    brandowner: {
      url:
        'https://members.junglescout.com/#/registrations?mt=54bf6ba3fed49204c24f8c24e36f5772',
      price: 1890.0
    }
  }
}

export const EU_PLANS = {
  monthly: {
    starter: {
      url:
        'https://members.junglescout.com/#/registrations?mt=202494b8cab85ffb68565b1bda911bfd',
      price: 39.0
    },
    growthaccelerator: {
      url:
        'https://members.junglescout.com/#/registrations?mt=8dd278db117996614b3a40ed591d919d',
      price: 59.0
    },
    brandowner: {
      url:
        'https://members.junglescout.com/#/registrations?mt=59fdfe3bfeba363a7ad97c2705ed5ab5',
      price: 370.0
    }
  },
  yearly: {
    starter: {
      url:
        'https://members.junglescout.com/#/registrations?mt=2f33cb3f5565967423be1ff026223a33',
      price: 289.0
    },
    growthaccelerator: {
      url:
        'https://members.junglescout.com/#/registrations?mt=82f21e687de8ca6e0c15b4dda13e529b',
      price: 489.0
    },
    brandowner: {
      url:
        'https://members.junglescout.com/#/registrations?mt=4743af4e5090813a7a6f8decd5157cb6',
      price: 3325.0
    }
  }
}

export const IN_PLANS = {
  monthly: {
    starter: {
      url:
        'https://members.junglescout.com/#/checkout?mt=2eadd0cd222ad22d6a68b72a61e550d2&lang=en-US',
      price: 3499.0
    },
    growthaccelerator: {
      url:
        'https://members.junglescout.com/#/registrations?mt=1adf1fc13c6a1050da068151a9112bf9&lang=en-US',
      price: 4999.0
    },
    brandowner: {
      url:
        'https://members.junglescout.com/#/registrations?mt=52d2b716ee29c72c4c83f5667aa7fc06&lang=en-US',
      price: 33400.0
    }
  },
  yearly: {
    starter: {
      url:
        'https://members.junglescout.com/#/checkout?mt=c15fa8e0fdfc34856c854a1f1e35812e&lang=en-US',
      price: 24999.0
    },
    growthaccelerator: {
      url:
        'https://members.junglescout.com/#/registrations?mt=3f47f68cbc10f47b5e8e0a1ee10aaaeb&lang=en-US',
      price: 42999.0
    },
    brandowner: {
      url:
        'https://members.junglescout.com/#/registrations?mt=ebfb89fd77295f94d8a4c147a1cf197a&lang=en-US',
      price: 300563.0
    }
  }
}

export const JP_PLANS = {
  monthly: {
    starter: {
      url:
        'https://members.junglescout.com/#/registrations?mt=7f30a1c2a9002ca1891efb4690b9eea2&lang=ja-JP',
      price: 5200.0
    },
    growthaccelerator: {
      url:
        'https://members.junglescout.com/#/registrations?mt=c2f890d7d34503f718760704d5052dea&lang=ja-JP',
      price: 7500.0
    },
    brandowner: {
      url:
        'https://members.junglescout.com/#/registrations?mt=12ec4dea0ffe5af0aafb3a39ebdd385a&lang=ja-JP',
      price: 59540.0
    }
  },
  yearly: {
    starter: {
      url:
        'https://members.junglescout.com/#/registrations?mt=d4a156baa162ca50aad122da918da37f&lang=ja-JP',
      price: 37000.0
    },
    growthaccelerator: {
      url:
        'https://members.junglescout.com/#/registrations?mt=9fb2db18c356c77a99cb89a222c6c31d&lang=ja-JP',
      price: 13500.0
    },
    brandowner: {
      url:
        'https://members.junglescout.com/#/registrations?mt=73f15929fb9fd9a32605d08c8f732572&lang=ja-JP',
      price: 535616.0
    }
  }
}
